import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Options } from 'ng5-slider';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit {

  @Output() applyFilterObj = new EventEmitter<string>();

  @Input('type_page') type_page:any;

  constructor(public productService: ProductsService, public matSnackBar: MatSnackBar,
    public router: Router) { }

  fromPrice;
  toPrice;

  value: number = 0;
  highValue: number = 10000;
  options: Options = {
    floor: 0,
    ceil: 10000,
    step: 1000
  };

  categoryList = [];

  selCategoryList = []
  categoryItem;
  ngOnInit() {
    if (JSON.parse(localStorage.getItem('category'))) {
      this.categoryItem = JSON.parse(localStorage.getItem('category'))
      this.selCategoryList.push(this.categoryItem.id)
    }
    this.getCategories()
  }

  clearfilter() {
    if(this.selCategoryList.length > 0 || this.fromPrice > 0 || this.toPrice != 10000){
      this.selCategoryList = []
      this.fromPrice = '';
      this.toPrice = '';
      this.value = 0;
      this.highValue = 10000;
      this.applyFilterObj.emit('')
    }else{
      this.selCategoryList = []
      this.fromPrice = '';
      this.toPrice = '';
      this.value = 0;
      this.highValue = 10000;
      this.applyFilterObj.emit('')
    }
  
  }

  isNumber(event){
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
        return true;
    } else if ( key < 48 || key > 57 ) {
        return false;
    } else {
        return true;
    }
  }

  errorMsg:string='';

  applyfilter() {
    this.errorMsg = '';
    if(this.fromPrice && this.toPrice){
      if(parseInt(this.fromPrice) < parseInt(this.toPrice)){
        let reqObj = {
          category: this.selCategoryList,
          toprice: this.toPrice,
          fromprice: this.fromPrice
        }
        this.applyFilterObj.emit(JSON.stringify(reqObj))
      }else{
        this.errorMsg = 'Min Value should be less than Max Value';
        // setTimeout(()=>{
        //   this.errorMsg = '';
        // }, 3000)
        // this.matSnackBar.open('Min Value should be less than Max Value', 'OK',{
        //   verticalPosition:'top',
        //   duration: 3000,
        //   panelClass:'error'
        // })
      }
    }else{
      let fromPrice;
      if(!this.fromPrice){
        fromPrice = 0;
      }else{
        fromPrice = this.fromPrice;
      }
      let toPrice;
      if(!this.toPrice){
        toPrice = 0;
      }else{
        toPrice = this.toPrice;
      }
      let reqObj = {
        category: this.selCategoryList,
        toprice: toPrice,
        fromprice: fromPrice
      }
      this.applyFilterObj.emit(JSON.stringify(reqObj))
    }
    // if (this.selCategoryList.length > 0) {
   
    // }
  }

  getCategories() {
    this.productService.getCategory().subscribe(res => {
      if (res.data.status == 'success') {
        this.categoryList = res.data.result;
      }
    })
  }

  selcategory(obj) {
    localStorage.setItem('category', JSON.stringify(obj))
    this.applyFilterObj.emit('category')
    this.router.navigate(['products/' + obj.title])
  }

  valueChange(ev: number, high): void {
 
    this.fromPrice = ev;
    this.toPrice = high;

  }

  changeto(ev) {
    if(parseInt(this.fromPrice) < parseInt(this.toPrice)){
      this.highValue = this.toPrice;
    }else{
      // this.matSnackBar.open('Max Value should be greater than Min Value', 'OK',{
      //   verticalPosition:'top',
      //   duration: 3000,
      //   panelClass:'error'
      // })
    }

  }

  changefrom(ev) { 
   
    if(this.toPrice){
      if(parseInt(this.fromPrice) < parseInt(this.toPrice)){
        this.value = this.fromPrice;
      }else{
        // this.matSnackBar.open('Min Value should be less than Max Value', 'OK',{
        //   verticalPosition:'top',
        //   duration: 3000,
        //   panelClass:'error'
        // })
      }
    }else{
      this.value = this.fromPrice;
    }

  }


}
