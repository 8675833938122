import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {



  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  productData: any;
  constructor(public appSettings: AppSettings, public appService: AppService, public dialog: MatDialog, private router: Router,
    public productsService: ProductsService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {


  }

  productdetails(productObj) {
    this.router.navigate(['/products/' + productObj.id + '/' + productObj.title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/ /g, '-').replace(/---/g, '-').replace(/--/g, '-').toLowerCase()])
  }



  ngAfterViewInit() {
    setTimeout(() => {
      this.config = {
        observer: true,
        slidesPerView: 6,
        spaceBetween: 16,
        keyboard: true,
        navigation: true,
        pagination: false,
        grabCursor: true,
        loop: false,
        preloadImages: false,
        lazy: true,
        initialSlide: 0,
        breakpoints: {
          480: {
            slidesPerView: 2
          },
          740: {
            slidesPerView: 2,
          },
          960: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
          1500: {
            slidesPerView: 5,
          }
        }
      }
    }, 1000)

  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog',
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(product => {
      if (product) {
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

}