import { Component, OnInit,Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {

  @Output() brandSel = new EventEmitter<string>();

  brands = [
    {title: 'no brand 1'},
    {title: 'China OEM'},
    {title: 'LALANG'},
    {title: 'FAREHA'},
    {title: 'Avon Products, Inc.'},
    {title: 'FAITOLAGI'},
    {title: 'Monnsley'},
    {title: 'ECMLN'},
    {title: 'AJM'},
    {title: 'NingDe'},
    {title: 'zanzea'},
    {title: 'NOBRAND'},
    {title: 'suylngla'},
    {title: 'INTIMA'},
    {title: '17 MILE'},
    {title: 'AVON'},
    {title: 'FINE TOO'},
    {title: 'FINETOO'},
    {title: 'WJS'},
    {title: 'Lizida'},
    {title: 'FallSweet'},
    {title: 'TIZHAN'},
    {title: 'Xiaozhainv'},
    {title: 'LouisWill'},
    {title: 'MeooLiisy'},
    {title: 'Hang-Qiao'}
  ]


  selBrandArr = [];

  constructor() { }

  ngOnInit() {
  }

  selBrand(obj){
    if(!this.selBrandArr.includes(obj)){
      this.selBrandArr.push(obj);
      this.brandSel.emit(JSON.stringify(this.selBrandArr));
    }else{
      let i =  this.selBrandArr.indexOf(obj);
      this.selBrandArr.splice(i,1);
      this.brandSel.emit(JSON.stringify(this.selBrandArr));
    }
  }

}
