import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];

  public config = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(public router: Router, public productsService: ProductsService) { }

  bannerData: any[] = []
  getBanners() {
    this.productsService.getBanners().subscribe(res => {
      if (res.data.status == 'success') {
        this.bannerData = res.data.result;

      }
    })
  }

  ngOnInit() {
    this.getBanners()


  }

  ngAfterViewInit() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },

      // breakpoints: {
      //   575.98: {
      //     slidesPerView: 2,
      //     spaceBetween: 20,
      //   }
      // },

      speed: 500,
      effect: "slide"
    }
    console.log(this.slides)
  }

  redirect(slideobj) {
    if (slideobj.redirect_type == 'external') {
      window.open(slideobj.redirect_url, '_blank')
    } else if (slideobj.redirect_type == 'internal') {
      this.router.navigate(['/seller/' + slideobj.redirect_id])
    }
  }

}