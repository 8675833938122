import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/providers/products.service';
import { SignupService } from 'src/app/providers/signup.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  searchHistoryList = [];

  constructor(public productService: ProductsService, public router: Router, public loginService: SignupService) { }

  ngOnInit() {
    this.getSearchHistory()
    // this.getupdated()
  }

  getupdated(){
    this.loginService.searchHistory.subscribe(res=>{
      if(res){
    this.getSearchHistory()
      }
    })
  }

  gotoproducts(obj) {
    this.loginService.addSearch(obj.keyword)
    this.router.navigate(['/products/' + obj.keyword])
   
  }

  getSearchHistory() {
    this.searchHistoryList=[]
    this.productService.getSearchhistory().subscribe(res => {
      if (res.data.status == 'success') {
        this.searchHistoryList = res.data.result.data.reverse();
      }
    })
  }

  openMegaMenu() {
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

}
