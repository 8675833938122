import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input() ratingsValue: any;
  @Input() direction: string;
  avg: number;
  stars: Array<string>;

  productRating:any;
  constructor() { }

ngOnInit(): void {
  if(this.ratingsValue.product_statistics){
    this.productRating = parseFloat(this.ratingsValue.product_statistics.rating_average)

  }
}


}
