import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  @Output() fuelSel = new EventEmitter<string>();

  categoryList = [];

  selCategoryList = []

  constructor(public productService: ProductsService) { }

  ngOnInit() {
    this.getCategories()
  }

  getCategories() {
    this.productService.getCategory().subscribe(res => {
      if (res.data.status == 'success') {
        this.categoryList = res.data.result;
      }
    })
  }

  selcategory(obj) {
    if (!this.selCategoryList.includes(obj)) {
      this.selCategoryList.push(obj);
      this.fuelSel.emit(JSON.stringify(this.selCategoryList));
    } else {
      let i = this.selCategoryList.indexOf(obj);
      this.selCategoryList.splice(i, 1);
      this.fuelSel.emit(JSON.stringify(this.selCategoryList));
    }
  }

}
