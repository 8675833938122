import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-monthly-star-carousel',
  templateUrl: './monthly-star-carousel.component.html',
  styleUrls: ['./monthly-star-carousel.component.scss']
})
export class MonthlyStarCarouselComponent implements OnInit {

  @Input() products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  productData: any;
  constructor(public appSettings: AppSettings, public appService: AppService, public dialog: MatDialog, private router: Router,
    public productsService: ProductsService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }

  productdetails(productObj) {
    this.router.navigate(['/products/' + productObj.id + '/' + productObj.title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/ /g, '-').replace(/---/g, '-').replace(/--/g, '-').toLowerCase()])
  }

  getProducts() {
    this.productsService.getProducts('product/list?type=home&limit=10').subscribe(res => {
      if (res.data.status == "success") {
        this.productData = res.data.result;
        this.products = this.productData.data
        console.log(this.products)
      }
    })
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 6,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        740: {
          slidesPerView: 3,
        },
        960: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 6,
        },
        1500: {
          slidesPerView: 6,
        }
      }
    }
    console.log(this.products)
  }


}