import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
// import {coerceNumberProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {
  

  fromPrice = 50000;
  toPrice = 500000;

  value: number = 50000;
  highValue: number = 500000;
  options: Options = {
    floor: 50000,
    ceil: 500000,
    step:50000
  };


  constructor() { }

  ngOnInit() {
  }
  
  valueChange(ev: number,high): void {
    this.fromPrice = this.value;
    this.toPrice = high-this.options.step;
    // console.log(high)
    // console.log(ev)
    // if (ev > 50000 ){
    //     this.fromPrice = this.value;
    // }

    // else if (ev < 500000){
    //   console.log(ev)
    //   this.toPrice = high;
    // }
  }

  changeto(ev){
    this.highValue = this.toPrice;

  }

  changefrom(ev){
    this.value = this.fromPrice;

  }

}
