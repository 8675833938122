import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public clockDisplay: BehaviorSubject<string> = new BehaviorSubject<string>('');


  constructor(public http: HttpClient, public AppConfig: AppConfig) { }


  public cartData: BehaviorSubject<string> = new BehaviorSubject<string>('');

  saveClockDisplay(data) {
    this.clockDisplay.next(data);
  }


  cartDataLoad(data) {
    this.cartData.next(data);
  }


  checkoutAborted(reqData, token, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.put(this.AppConfig.API_ENDPOINT + 'checkout/' + id, reqData, httpOptions)
      .pipe(map((res: any) => res))
  }


  checkoutStart(reqData, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'checkout', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  addBillingAddress(reqData, token, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    // return this.http.post(this.AppConfig.API_ENDPOINT+'shipping-methods',reqData,  httpOptions)
    return this.http.post(this.AppConfig.API_ENDPOINT + 'checkout/' + id + '/estimate-shipping-fee', reqData, httpOptions)


      .pipe(map((res: any) => res))
  }

  applyCoupon(reqData, token, checkoutid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    // return this.http.get(this.AppConfig.API_ENDPOINT + 'apply-coupon?code=' + reqData, httpOptions)
    //   .pipe(map((res: any) => res))

    return this.http.post(this.AppConfig.API_ENDPOINT + 'checkout/' + checkoutid +'/apply-offer' , reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  orderPlace(reqData, token, checkoutid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.put(this.AppConfig.API_ENDPOINT + 'checkout/' + checkoutid, reqData, httpOptions)
      .pipe(map((res: any) => res))
  }


  deletecartItem(reqData, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'cart', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  deleteCart(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.delete(this.AppConfig.API_ENDPOINT + 'cart', httpOptions)
      .pipe(map((res: any) => res))
  }

  addtocart(reqData, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'cart', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  updateCart(reqData, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.put(this.AppConfig.API_ENDPOINT + 'cart', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getCartProduct(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'cart', httpOptions)
      .pipe(map((res: any) => res))
  }


  getpaymentMethods(checkoutid, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'checkout/' + checkoutid + '/payment-methods', httpOptions)
      .pipe(map((res: any) => res))
  }


  placeOrder(reqObj, token, url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.post(url, reqObj, httpOptions)
      .pipe(map((res: any) => res))
  }


}


