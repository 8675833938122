import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { SignupService } from '../providers/signup.service';
import { CartService } from '../providers/cart.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Location } from '@angular/common'
import { ProductsService } from '../providers/products.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [SidenavMenuService]
})
export class PagesComponent implements OnInit {
  public showBackToTop: boolean = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  showBreadCrumb: boolean = true;
  userToken;
  searchItem;
  routing;
  @ViewChild('sidenav', { static: true }) sidenav: any;
  @ViewChild('snav', { static: false }) snav: MatSidenav;
  showSearch: boolean = true;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    public loginService: SignupService,
    public cartservice: CartService,
    public location: Location,
    public productsService: ProductsService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show progress spinner or progress bar
        console.log('Route change detected');
      }

      if (event instanceof NavigationEnd) {
        console.log(event)
        this.searchItem = ''
        // this.currentRoute = event.url;          
        if (event.url.includes('products')) {
          this.showBreadCrumb = true;
        } else {
          this.showBreadCrumb = false;
        }
        this.routing = event;
        if (event.url == '/') {
          this.showSearch = true;
        } else {
          this.showSearch = false;
        }

      }

      if (event instanceof NavigationError) {
        // console.log(event.error);
      }
    });
  }

  goback() {
    if (this.routing.url.includes('account')) {
      this.router.navigate(['/'])
    } else {
      this.location.back();

    }
  }

  userProfile;
  profileUpdate() {
    this.loginService.userData.subscribe(res => {
      if (res) {
        this.userToken = localStorage.getItem('userToken')
        this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
      }
      this.getCartItems()

      this.getCartUpdate()

    })
  }


  totalCartCount = 0;
  getCartItems() {
    if (this.userToken) {
      this.totalCartCount = 0;

      this.cartservice.getCartProduct(this.userToken).subscribe(res => {
        // console.log(res)

        if (res.data.status == "success") {
          if (res.data.result) {
            this.cartItems = res.data.result.items;
          }
          // this.cartItems.forEach(cart => {
          //   this.totalCartCount = this.totalCartCount + (cart.quantity * parseInt(cart.product_variant.unit_price))
          // })
        }
      })
    }

  }
  cartItems = [];
  getCartUpdate() {
    this.cartservice.cartData.subscribe(data => {
      if (data) {
        this.getCartItems()
      }
    })
  }

  logout() {
    localStorage.removeItem('userToken')
    localStorage.removeItem('userProfile')
    this.userToken = '';
    this.userProfile = '';
    this.cartItems = [];

    this.loginService.loginData('')
    this.router.navigate(['/'])
    this.showModal = false;
    this.snav.close()
  }

  showModal: boolean = false;
  openmenu() {
    this.showModal = !this.showModal;
    this.snav.toggle()
  }

  ngOnInit() {
    // this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    this.userToken = localStorage.getItem('userToken')
    this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.profileUpdate()

  }

  public getCategories() {
    this.appService.getCategories().subscribe(data => {
      this.categories = data;
      this.category = data[0];
      this.appService.Data.categories = data;
    })
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
      this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
      this.appService.resetProductCartCount(product);
    }
  }

  public clear() {
    this.appService.Data.cartList.forEach(product => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  public search() {
    this.loginService.addSearch(this.searchItem)
    this.router.navigateByUrl('/products/' + this.searchItem)
  }

  searchSeller() {
    this.router.navigateByUrl('/seller-list/' + this.searchItem)
  }


  public scrollToTop() {
    window.scrollTo(0, 0);
    // var scrollDuration = 200;
    // var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    // var scrollInterval = setInterval(()=>{
    //   if(window.pageYOffset != 0){
    //      window.scrollBy(0, scrollStep);
    //   }
    //   else{
    //     clearInterval(scrollInterval); 
    //   }
    // },10);
    // if(window.innerWidth <= 768){
    //   setTimeout(() => {
    //     if (isPlatformBrowser(this.platformId)) {
    //       window.scrollTo(0,0);
    //     } 
    //   });
    // }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

}