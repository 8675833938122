import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { CartService } from 'src/app/providers/cart.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductsService } from 'src/app/providers/products.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() productdetails: any;
  @Input() coverVariant: any;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSelectSize: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = 'center center';
  userToken;
  cartItems = [];
  constructor(public appService: AppService, public snackBar: MatSnackBar,
    public cartService: CartService, public productService: ProductsService, public dialog: MatDialog,
    public router: Router) { }

  ngOnInit() {
    if (this.productdetails) {
      if (this.productdetails.cartCount > 0) {
        this.count = this.productdetails.cartCount;
      } else if (this.productdetails.quantity > 0) {
        this.count = this.productdetails.quantity;

      }
    }
    this.layoutAlign();
    if (localStorage.getItem('userToken')) {
      this.userToken = localStorage.getItem('userToken')
    }

    this.layoutAlign();
  }

  getCartItems() {
    // this.totalCartCount=0;
    // this.cartItems =[]
    this.cartService.getCartProduct(this.userToken).subscribe(res => {

      if (res.data.status == "success") {
        this.cartItems = res.data.result.items;
        // this.totalCartCount = 0;
        let totalCartItems = 0;
        let total = 0
        this.cartItems.forEach(cart => {
          totalCartItems = totalCartItems + cart.quantity;
          cart.message_seller = '';
          cart.itemTotal = cart.product_variant.unit_price * cart.quantity;

        })
        let cartArray = [];
        this.cartItems.forEach(cart => {
          if (cart.size) {
            cartArray.push({
              cart_item_id: cart.id,
              quantity: cart.quantity,
              unit_price: cart.product_variant.unit_price,
              item_quantity_price: cart.quantity * cart.product_variant.unit_price,
              message_to_seller: cart.message_seller,
              "custom_size": {
                "name": cart.size.name,
                "uom": cart.size.uom,
                "size_details": {
                  "length": cart.size.size_details.body_length,
                  "chest_width": cart.size.size_details.chest_width,
                  "length_type": cart.size.size_details.length_type,
                  "waist_width": cart.size.size_details.waist_width,
                  "fitting_type": cart.size.size_details.fitting_type,
                  "sleeves_type": cart.size.size_details.sleeves_type,
                  "sleeve_length": cart.size.size_details.sleeve_length
                }
              },
            })
          } else {
            cartArray.push({
              cart_item_id: cart.id,
              quantity: cart.quantity,
              unit_price: cart.product_variant.unit_price,
              item_quantity_price: cart.quantity * cart.product_variant.unit_price,
              message_to_seller: cart.message_seller,

            })
          }
        })

        let reqObj = {
          "subtotal": this.cartItems[0].quantity * parseFloat(this.cartItems[0].product_variant.unit_price),
          "discount": '',
          "shipping_fee": '',
          "total": this.cartItems[0].quantity * parseFloat(this.cartItems[0].product_variant.unit_price),
          "cart": cartArray
        }
        this.cartService.checkoutStart(reqObj, this.userToken).subscribe(res => {
          if (res.data.status == 'success') {
            localStorage.setItem('checkoutData', JSON.stringify(res.data.result));
            localStorage.setItem('checkoutItems', JSON.stringify(res.data.result.items))
            this.router.navigate(['/checkout'])
          }
        }, err => {
          // this.spinner.hide()
          if (err.error.data.errors.length > 0) {
            this.snackBar.open(err.error.data.errors[0], '', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'error'
            })
          } else {
            this.snackBar.open(err.error.data.status_message, '', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'error'
            })
          }
        })

      } else {
        // this.spinner.hide()
        this.snackBar.open(res.error.data.status_message, '', {
          verticalPosition: 'top',
          duration: 2000,
          panelClass: 'error'
        })
      }
    }, err => {
      // this.spinner.hide()
      this.snackBar.open(err.error.data.status_message, '', {
        verticalPosition: 'top',
        duration: 2000,
        panelClass: 'error'
      })
    })
  }

  buynow(cart) {
    let size;
    let reqObj1 = {
      variant_id: this.coverVariant.id,
      quantity: this.count,
      size_id: size
    }

    this.cartService.addtocart(reqObj1, this.userToken).subscribe(res => {
      // console.log(res)
      if (res.data.status == 'success') {
        // this.snackBar.open(res.data.status_message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
        this.cartService.cartDataLoad('cart added')
        this.getCartItems()
      }
    })

  }

  notifyme(obj) {
    const dialogref = this.dialog.open(NotifyMeDialog, {
      width: '500px',
      panelClass: 'reviewsdilogue',
      data: obj
    });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        let reqObj = {
          email: result.email,
          product_id: this.productdetails.id,
          variant_id: this.coverVariant.id,
          event_name: 'back_in_stock',
        }
        this.productService.notifyMe(reqObj).subscribe(res => {
          if (res.data.status == 'success') {
            this.snackBar.open(res.data.status_message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          }
        }, err => {
          if (err.error.data.errors.length > 0) {
            this.snackBar.open(err.error.data.errors[0], '', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'error'
            })
          } else {
            this.snackBar.open(err.error.data.status_message, '', {
              verticalPosition: 'top',
              duration: 2000,
              panelClass: 'error'
            })
          }
          // this.errorMsg = err.error.data.message;

        })
      }
    })
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }



  public increment() {
    // console.log(this.count)
    // if(this.count < this.coverVariant.available_units){
    this.count++;
    let obj = {
      productId: this.productdetails.id,
      soldQuantity: this.count,
      total: this.count * parseFloat(this.coverVariant.unit_price),
      type: 'inc'
    }
    this.changeQuantity(obj);
    // }
    // else{
    //   this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    // }    
  }

  public decrement() {
    if (this.count > 1) {
      this.count--;
      let obj = {
        productId: this.productdetails.id,
        soldQuantity: this.count,
        total: this.count * this.productdetails.newPrice,
        type: 'dec'
      }
      this.changeQuantity(obj);
    }
  }

  public addToCompare(product: Product) {
    this.appService.addToCompare(product);
  }

  public addToWishList(product: Product) {
    this.appService.addToWishList(product);
  }

  public addToCart(product) {

    if (this.coverVariant.available_units > 0) {
      if (this.userToken) {
        let size;

        let reqObj = {
          variant_id: this.coverVariant.id,
          quantity: this.count,
          size_id: size
        }

        this.cartService.addtocart(reqObj, this.userToken).subscribe(res => {
          // console.log(res)
          if (res.data.status == 'success') {
            this.snackBar.open(res.data.status_message, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            this.cartService.cartDataLoad('cart added')
          }
        })
      } else {
        this.snackBar.open('Please Login to continue', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

      }
    }


  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

}


@Component({
  selector: 'app-motify-me',
  templateUrl: './notify-me.html',
  styleUrls: ['./controls.component.scss']
})

export class NotifyMeDialog {

  form: FormGroup;
  userToken;
  userProfile;
  constructor(public dialogRef: MatDialogRef<NotifyMeDialog>, public formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required]
    })
    if (localStorage.getItem('userToken')) {
      this.userToken = localStorage.getItem('userToken')
      this.userProfile = JSON.parse(localStorage.getItem('userProfile'));
      this.form = this.formBuilder.group({
        name: [this.userProfile.full_name, Validators.required],
        email: [this.userProfile.email, Validators.required]
      })
    }
  }

  close() {
    this.dialogRef.close()
  }

  submit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value)
    } else {

    }
  }

}