import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { Product } from "../../app.models";
import { Settings, AppSettings } from 'src/app/app.settings';
import { ProductsService } from 'src/app/providers/products.service';

@Component({
  selector: 'app-just-arrived-carousel',
  templateUrl: './just-arrived-carousel.component.html',
  styleUrls: ['./just-arrived-carousel.component.scss']
})
export class JustArrivedCarouselComponent implements OnInit {

  @Input('products') products: any[] = [];
  public config = {};
  public settings: Settings;
  productData: any;
  constructor(public appSettings: AppSettings, public appService: AppService, public dialog: MatDialog, private router: Router,
    public productsService: ProductsService) {

    this.settings = this.appSettings.settings;

  }

  ngOnInit() {
    console.log(this.products)
  }

  productdetails(productObj) {
    this.router.navigate(['/products/' + productObj.id + '/' + productObj.title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '').replace(/ /g, '-').replace(/---/g, '-').replace(/--/g, '-').toLowerCase()])
  }


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 6,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      initialSlide: 0,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        740: {
          slidesPerView: 3,
        },
        960: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 6,
        },
        1500: {
          slidesPerView: 6,
        }
      }
    }
  }

}