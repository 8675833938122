import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public sizeSelect: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public searchItem: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(public http: HttpClient, public AppConfig: AppConfig) { }



  userSelects(data) {
    this.sizeSelect.next(data)
  }

  saveSearchItem(data) {
    this.searchItem.next(data)
  }

  getProducts(url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + url, httpOptions)
      .pipe(map((res: any) => res))

  }


  prouctDetails(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/' + id + '/details', httpOptions)
      .pipe(map((res: any) => res))
  }

  productDetails(id, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/' + id + '/details', httpOptions)
      .pipe(map((res: any) => res))
  }

  getCategoryBasedProducts(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/list?type=category&category_id=' + id + '&limit=5', httpOptions)
      .pipe(map((res: any) => res))
  }

  getCategory() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'category', httpOptions)
      .pipe(map((res: any) => res))
  }


  getSizeChartOld(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'suggest-size?uom=' + reqData.uom + '&height=' + reqData.height + '&weight=' + reqData.weight + '&shoe_size=' + reqData.shoe_size + '&age=' + reqData.age, httpOptions)
      .pipe(map((res: any) => res))
  }

  getSizeChart(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'suggest-size', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  savemysizes(token, reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'account/sizes', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getPageReviews(token, url) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }
    return this.http.get(url, httpOptions)
      .pipe(map((res: any) => res))
  }

  getReviewsWithoutToken(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/' + id + '/reviews', httpOptions)
      .pipe(map((res: any) => res))
  }

  getReviews(token, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/' + id + '/reviews', httpOptions)
      .pipe(map((res: any) => res))
  }

  getBanners() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'banners', httpOptions)
      .pipe(map((res: any) => res))
  }


  notifyMe(reqData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'notification-events', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  addToWishList(reqData, token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    }

    return this.http.post(this.AppConfig.API_ENDPOINT + 'account/wishlist', reqData, httpOptions)
      .pipe(map((res: any) => res))
  }

  getWishlist(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'account/wishlist', httpOptions)
      .pipe(map((res: any) => res))
  }

  removeWishlist(token, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': token
      })
    }

    return this.http.delete(this.AppConfig.API_ENDPOINT + 'account/wishlist/' + id, httpOptions)
      .pipe(map((res: any) => res))
  }

  productPriceOnModel(reqObj, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'product/' + id + '/model-details?product_option_id=' + reqObj, httpOptions)
      .pipe(map((res: any) => res))
  }


  getSellerDetails(uid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'seller/' + uid + '/details', httpOptions)
      .pipe(map((res: any) => res))
  }


  getSearchhistory() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.get(this.AppConfig.API_ENDPOINT + 'search-history', httpOptions)
      .pipe(map((res: any) => res))
  }



}


