import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './app-config';

@Injectable({
  providedIn: 'root'
})
export class SignupService {


  public userData: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public searchHistory: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(public http:HttpClient, public AppConfig:AppConfig) { }

  emailSubscription(email){
    const httpOptions = {
      headers: new  HttpHeaders ({
        'Content-Type': 'application/json'
      })
    }

    return this.http.get<any>(this.AppConfig.API_ENDPOINT+'subscribe?email='+email,  httpOptions);
 
  }

  addSearch(data) {
    this.searchHistory.next(data);
}

  loginData(data) {
    this.userData.next(data);
}

userCheck(userInfo){

  const httpOptions = {
    headers: new  HttpHeaders ({
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  }

  let body = new URLSearchParams();

  body.append('provider_uid', userInfo.id)
  body.append('provider', userInfo.provider)
  body.append('type', 'social')
  body.append('email', userInfo.email)
 
     return this.http.post<any>(this.AppConfig.API_ENDPOINT+'login', body.toString(), httpOptions);
 
}

public forgotpassword(reqData) {
  const httpOptions = {
    headers: new  HttpHeaders ({
      'Content-Type': 'application/json'
    })
  } 

  return this.http.get(this.AppConfig.API_ENDPOINT+'password/reset?email='+reqData.email, httpOptions)
  .pipe(map((res:any)=>res))

}

setPassword(reqData) {

  const httpOptions = {
    headers: new  HttpHeaders ({
      'Content-Type': 'application/json'
    })
  } 

  return this.http.post(this.AppConfig.API_ENDPOINT+'password/reset', reqData, httpOptions)
  .pipe(map((res:any)=>res))

}

verifyPasswordReset(reqData){
  const httpOptions = {
    headers: new  HttpHeaders ({
      'Content-Type': 'application/json'
    })
  } 

  return this.http.post(this.AppConfig.API_ENDPOINT+'verify-email',reqData, httpOptions)
  .pipe(map((res:any)=>res))
}

verifyUser(reqData){
  const httpOptions = {
    headers: new  HttpHeaders ({
      'Content-Type': 'application/json'
    })
  } 

  return this.http.post(this.AppConfig.API_ENDPOINT+'verify-email',reqData, httpOptions)
  .pipe(map((res:any)=>res))
}

  signup(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    let body = new URLSearchParams();
  if(reqData.type == 'email'){
    body.append("type",'email');
    body.append("country_code",reqData.country_code);
    body.append("phone",reqData.phone);
    body.append("username",reqData.username);
    body.append("email",reqData.email);
    body.append("password",reqData.password);
    body.append("password_confirmation",reqData.password_confirmation);
    body.append("full_name", reqData.name);
  }else{
      body.append("type",'social');
      body.append("country_code",reqData.country_code);
      body.append("phone",reqData.phone);
      body.append("username",reqData.username);
      body.append("email",reqData.email);
      body.append("password",reqData.password);
      body.append("password_confirmation",reqData.password_confirmation);
      body.append("full_name", reqData.name);
      body.append("provider", reqData.provider);
      body.append("provider_uid", reqData.provider_uid);
  }

    return this.http.post(this.AppConfig.API_ENDPOINT+'signup', body.toString(), httpOptions)
    .pipe(map((res:any)=>res))
  }


  logout(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization':token
      })
    }
    return this.http.get(this.AppConfig.API_ENDPOINT+'logout', httpOptions)
    .pipe(map((res:any)=>res))

  }


  login(reqData){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    }

    let body = new URLSearchParams();

    body.append('email', reqData.email);
    body.append('password', reqData.password);

  body.append("type",'email');


    return this.http.post(this.AppConfig.API_ENDPOINT+'login', body.toString(), httpOptions)
    .pipe(map((res:any)=>res))
  }


}
