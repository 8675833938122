import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Account Settings' } },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'Compare' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
            { path: 'payment', loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule), data: { breadcrumb: 'Payment' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
            { path: 'checkout-expired', loadChildren: () => import('./pages/checkout-failed/checkout-failed.module').then(m => m.CheckoutFailedModule), data: { breadcrumb: 'Checkout-Expired' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
            { path: 'order-confirmed/:payment', loadChildren: () => import('./pages/order-confirmed/order-confirmed.module').then(m => m.OrderConfirmedModule), data: { breadcrumb: 'Order Confirmed' } },
            { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' } },
            { path: 'sign-up', loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpModule), data: { breadcrumb: 'Sign Up ' } },
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'Brands' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Products' } },
            { path: 'seller/:uid', loadChildren: () => import('./pages/seller/seller.module').then(m => m.SellerModule), data: { breadcrumb: 'Seller' } },
            { path: 'terms-of-use', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule), data: { breadcrumb: 'Terms of Use' } },
            { path: 'return-policy', loadChildren: () => import('./pages/return-policy/return-policy.module').then(m => m.ReturnPolicyModule), data: { breadcrumb: 'Privacy Policy' } },
            { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'Privacy Policy' } },
            { path: 'forgotpassword', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule), data: { breadcrumb: 'Forgot Password' } },
            { path: 'reset-password', loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule), data: { breadcrumb: 'Reset Password' } },
            { path: 'verify-signup-email', loadChildren: () => import('./pages/verify-signup-email/verify-signup-email.module').then(m => m.VerifySignupEmailModule), data: { breadcrumb: 'Verify Signup Email' } },
            { path: 'seller-list/:search', loadChildren: () => import('./pages/seller-list/seller-list.module').then(m => m.SellerListModule), data: { breadcrumb: 'Seller List' } },

        ]
    },
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    initialNavigation: 'enabled'  // for one load page, without reload
    // useHash: true
});